import { template as template_019ad595189f4b578405b48faa8da860 } from "@ember/template-compiler";
const FKControlMenuContainer = template_019ad595189f4b578405b48faa8da860(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_78a819df498f470cb1eea41e3d16e257 } from "@ember/template-compiler";
const FKLabel = template_78a819df498f470cb1eea41e3d16e257(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
